import * as React from "react";

import { TextField } from "@projectaltair/textfield";
import { useLocation, useNavigate } from "react-router-dom";

import "./index.scss";

//icon
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import { CapitalizeWords } from "../../atoms/CapitalizeWords";
import { useTheme } from "@mui/material";
import { useQueryClient } from "react-query";

interface Props {
  onSearch: (query: string) => void;
}

const { REACT_APP_URL_REDIRECT_GAME } = process.env;

const Navbar: React.FunctionComponent<Props> = ({ onSearch }: Props) => {
  const windowUrl = window.location.search;
  const paramsUrl = new URLSearchParams(windowUrl);

  const navigate = useNavigate();
  const path = useLocation();
  const locations = path.pathname;

  const typeTitle = paramsUrl.get("type");
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [errorMsg, setErrorMsg] = React.useState<string>("");

  const onClickBackToApp = () => {
    if (!!REACT_APP_URL_REDIRECT_GAME) {
      window.location.replace(REACT_APP_URL_REDIRECT_GAME);
    }
  };

  const onClickBack = () => {
    return navigate(-1);
  };

  // SEARCH
  const handleInputChange = (event: any) => {
    let values = event.target.value;
    setSearchInput(values);
    if(values.length < 3){
      setErrorMsg('Minimal 3 karakter')
    }else{
      setErrorMsg('');
    }
  };

  const theme = useTheme();

  React.useEffect(() => {
    const timerId = setTimeout(() => {
      onSearch(searchInput);
    });
    return () => clearTimeout(timerId);
  }, [searchInput, onSearch]);

  if (locations === "/") {
    return (
      <div className="appBar">
        <div className="navbar-wrapper">
          <a onClick={onClickBackToApp}>
            <ArrowBackIosIcon color="primary" className="backBtn" />
          </a>
          <TextField
            hiddenLabel
            placeholder="Cari topup & voucher"
            InputProps={{
              endAdornment: <SearchIcon color="primary" className="searchIcon" />,
            }}
            error={!!errorMsg}
            helperText={errorMsg}
            type="text"
            style={{ width: "100%" }}
            className="searchParams"
            inputProps={{
              style: {
                padding: "9px",
              },
            }}
            id="searchInputField"
            value={searchInput}
            onChange={handleInputChange}
          />
        </div>
      </div>
    );
  } else if (!!typeTitle) {
    return (
      <div className="navBar">
        <div className="navbar-wrapper">
          <a onClick={onClickBack}>
            <ArrowBackIosIcon color='primary' className="backBtn" />
          </a>
          <p className="navbarTitle" style={{ color: theme.palette.primary.main }}>{CapitalizeWords(typeTitle)}</p>
        </div>
      </div>
    );
  } else if (locations === "/detailpembayaran") {
    return (
      <div className="navBar">
        <div className="navbar-wrapper">
          <a onClick={onClickBack}>
            <ArrowBackIosIcon color='primary' className="backBtn" />
          </a>
          <p className="navbarTitle" style={{ color: theme.palette.primary.main }}>Detail Pembayaran</p>
        </div>
      </div>
    );
  }
};

export default Navbar;
