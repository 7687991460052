import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom';

import NavbarMain from '../../config/component/layouts/NavbarMain';
import MainRouters from '../../config/routes';
import PageNotFoundComponent from '../main/view/error_handling/PageNotFoundComponent';

import { useMainViewModel } from '../main/viewmodel/main/MainViewModel';
import { onShowLoading } from '../../config/utils/alerts';
import { ThemeProvider } from '@mui/material';

export const Routers = () => {

  const { searchQuery, handleSearch, themeState } = useMainViewModel();

  return (
    <div>
      <ThemeProvider theme={themeState}>
        <Suspense fallback={
          onShowLoading(true)
        }>
          <div className='main'>
            <NavbarMain onSearch={handleSearch}/>
            <Routes>
              {MainRouters.map(({ key, path, component: Component }) => (
                <Route
                  path={path}
                  key={key}
                  element={
                    <Component
                      query={searchQuery}
                    />
                  }
                />
              ))}
              <Route path="*" key={"key.404NotFound"} element={<PageNotFoundComponent />} />
            </Routes>
          </div>
        </Suspense>
      </ThemeProvider>
    </div>
  )
}
