import React from "react";

import { onShowAlerts } from "../../../../config/utils/alerts";
import { createTheme, debounce } from "@mui/material";
import { decodeBase64 } from "../../../../config/utils/main";
import Cookies from "universal-cookie";

export function useMainViewModel() {

    const cookies = new Cookies();

    const [searchQuery, setSearchQuery] = React.useState('');
    const token = cookies.get('auth-token');
    const params = cookies.get('params');
    const theme = cookies.get("theme");
    const [themeState, setThemeState] = React.useState(
      createTheme({
        palette: {
          primary: {
            main: "#ffffff", //main
          },
          secondary: {
            main: "#ffffff", //second
          },
          background: {
            default: "#ffffff", //fifth
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              contained: {
                backgroundColor: "##ffffff", //forth
                color: "#ffffff", //fourth
                "&:hover": {
                  backgroundColor: "#ffffff", //third
                },
              },
              outlined: {
                backgroundColor: "transparent",
                border: "1px solid",
                color: "#ffffff", //second
                "&:hover": {
                  backgroundColor: "#ffffff", //second
                  color: "#ffffff", //fourth
                },
              },
              text: {
                "&:hover": {
                  backgroundColor: "#ffffff",
                  color: "#ffffff", //third
                },
              },
            },
          },
        },
      })
    );
    
    const handleSearch = debounce((query: string) => {
        if(query.length >= 3 || query.length === 0){
            setSearchQuery(query);
        }
    }, 1000);

    React.useEffect(() => {
        const paramHeaders = !!params ? JSON.parse(decodeBase64(params)) : null;
        if (!token || !paramHeaders) {
            onShowAlerts(token ? "Invalid Parameter" : "Your session is ended. Please back to home apps.", !token);
        }
        
    }, [token, params]);

    React.useEffect(() => {
      try {
        if (theme !== undefined) {
        //   console.log(theme);


          const themeCookies = {
              primaryColor: theme.primaryColor, //main
              secondaryColor: theme.secondaryColor, //button color
              thirdColor: theme.thirdColor, //button hover color
              fourthColor: theme.fourthColor, //text on button
              fifthColor: theme.fifthColor, //bg
          };
        //   console.log(themeCookies);

          setThemeState(
          createTheme({
              palette: {
              primary: {
                  main: themeCookies.primaryColor, //main
              },
              secondary: {
                  main: themeCookies.secondaryColor, //second
              },
              background: {
                  default: themeCookies.fifthColor, //fifth
              },
              },
              components: {
              MuiButton: {
                  styleOverrides: {
                  contained: {
                      backgroundColor: themeCookies.secondaryColor, //second
                      color: themeCookies.fourthColor, //fourth
                      "&:hover": {
                      backgroundColor: themeCookies.thirdColor, //third
                      },
                  },
                  outlined: {
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: themeCookies.secondaryColor, //second
                      "&:hover": {
                      backgroundColor: themeCookies.secondaryColor, //second
                      color: themeCookies.fourthColor, //fourth
                      border: "0px",
                      },
                  },
                  text: {
                      "&:hover": {
                      backgroundColor: "white",
                      color: themeCookies.thirdColor, //third
                      },
                  },
                  outlinedSecondary: {
                      backgroundColor: "white",
                      border: "1px solid",
                      color: "#a2a2a2",
                      "&:hover": {
                      backgroundColor: "white",
                      color: themeCookies.secondaryColor, //second
                      border: "1px solid " + themeCookies.secondaryColor, //second
                      },
                      "&:focus": {
                      backgroundColor: "white",
                      color: themeCookies.secondaryColor, //second
                      border: "1px solid " + themeCookies.secondaryColor, //second
                      },
                      "&:active": {
                      backgroundColor: "white",
                      color: themeCookies.secondaryColor, //second
                      border: "1px solid " + themeCookies.secondaryColor, //second
                      },
                  },
                  },
              },
              },
          })
          );
        }
      } catch (error) {
        // console.log(error);
        onShowAlerts("Failed to load theme", true);
      }
    }, [theme]);

    return {
      searchQuery,
      handleSearch,
      themeState,
    };
}